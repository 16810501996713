import $ from 'jquery'
import { getClientStatusSets } from './api/status_repository'
import { getOrganizationLocations } from './api/organization_repository'

$(function () {
  const $clientSelect = $('.client-select')
  const $locationSelect = $('.location-select')
  const $statusSetSelect = $('.status-set-select')
  const $keySurveyChoice = $('.keysurvey-choice')
  const $moduleTypeSelect = $('.module-type-select')
  const $categoryChoice = $('.category-choice')

  if ($clientSelect.length) {
    $clientSelect.change(function () {
      $(document).trigger('organization:change', [$(this).val()])
    })
  }

  if ($locationSelect.length) {
    $locationSelect.change(function () {
      $(document).trigger('location:change', [$(this).val()])
    })
  }

  if ($statusSetSelect.length) {
    $statusSetSelect.change(function () {
      $(document).trigger('statusSet:change', [$(this).val()])
    })
  }

  if ($keySurveyChoice.length) {
    $keySurveyChoice.find('input').change(function () {
      $(document).trigger('keySurveyChoice:change', [$(this).val()])
    })

    $(document).trigger('keySurveyChoice:change', [$keySurveyChoice.find('input[checked="checked"]').val()])
  }

  if ($moduleTypeSelect.length) {
    $moduleTypeSelect.change(function () {
      $(document).trigger('moduleType:change', [$(this).attr('data-key'), $(this).val()])
    })

    $moduleTypeSelect.each(function (index, element) {
      $(element).trigger('moduleType:change', [$(element).attr('data-key'), $(element).val()])
    })
  }

  if ($categoryChoice.length) {
    $categoryChoice.find('input').change(function () {
      $(document).trigger('categoryChoice:change', [$(this).val()])
    })

    $(document).trigger('categoryChoice:change', [$categoryChoice.find('input[checked="checked"]').val()])
  }
})

$(document).on('organization:change', function (event, organizationId) {
  console.debug('organization:change')

  const $locationSelect = $('.location-select')
  const $specificInput = $('.specific-job-autocomplete')
  const $statusSetSelect = $('.status-set-select')
  const $keySurveySelect = $('.keysurvey-select')

  if ($locationSelect.length) {
    $locationSelect.empty()

    const placeholder = $locationSelect.data('placeholder') !== undefined ? $locationSelect.data('placeholder') : 'Select a location'

    $locationSelect.append('<option value="">' + placeholder + '</option>')

    if (organizationId > 0) {
      getOrganizationLocations(organizationId, 0).then(collection => collection.items.map(location => $locationSelect.append('<option value="' + location.id + '">' + location.name + '</option>')))
    }
  }

  if ($specificInput.length) {
    if (organizationId > 0) {
      $specificInput.autocomplete({
        source: 'api/v1/clients/' + organizationId + '/specificJobs'
      })
    } else {
      if ($specificInput.autocomplete()) {
        $specificInput.autocomplete('destroy')
      }
    }
  }

  if ($statusSetSelect.length) {
    const id = organizationId > 0 ? organizationId : 1

    getClientStatusSets(id).then(collection => {
      $statusSetSelect.empty()
      let defaultFound = false

      collection.items.forEach(function (statusSet) {
        let option = '<option value="' + statusSet.id + '"'

        if (statusSet.default_cycle_status_set && !defaultFound) {
          option += ' selected '
          defaultFound = true
        }

        option += '>' + statusSet.name + '</option>'
        $statusSetSelect.append(option)
      })
    })
  }

  if ($keySurveySelect.length) {
    $keySurveySelect.empty().append('<option value="">Select a survey</option>')

    if (organizationId > 0) {
      $.get('api/v1/clients/' + organizationId + '/surveys', function (response) {
        $.each(response.data, function (id, survey) {
          $keySurveySelect.append('<option value="' + survey.id + '">' + survey.title + '</option>')
        })
      },
      'json'
      )
    }
  }
})

$(document).on('statusSet:change', function (event, statusSetId) {
  console.debug('statusSet:change')

  if (parseInt(statusSetId) !== parseInt($('.status-set-select').data('originalStatusSetId'))) {
    $('#status-set-change').foundation('reveal', 'open')
  }
})

$(document).on('keySurveyChoice:change', function (event, choice) {
  console.debug('keySurveyChoice:change')

  const $select = $('.keysurvey-select')
  const $input = $('.keysurvey-text-field')

  if (parseInt(choice) === 1) {
    $select.show()
    $input.hide()
  } else {
    $select.hide()
    $input.show()
  }
})

$(document).on('categoryChoice:change', function (event, choice) {
  console.debug('categoryChoice:change')

  const $select = $('.category-select').parent()

  if (parseInt(choice) === 1) {
    $select.show()
  } else {
    $select.hide()
  }
})

$(document).on('moduleType:change', function (event, moduleId, typeId) {
  console.debug('moduleType:change')

  if ((typeId === '1') || (typeId === '11')) {
    $(`#published-assessment-select-${moduleId}`).show()
  } else {
    $(`#published-assessment-select-${moduleId}`).hide()
  }

  if (typeId === '3') {
    $(`#baselineSelector${moduleId}`).show();
  } else {
    $(`#baselineSelector${moduleId}`).hide();
  }

  if (typeId === '5') {
    $(`#competencySelector${moduleId}`).show();
  } else {
    $(`#competencySelector${moduleId}`).hide();
  }

  if (typeId === '6') {
    $(`#nestedSelector${moduleId}`).show();
  } else {
    $(`#nestedSelector${moduleId}`).hide();
  }

  if (typeId === '8' || typeId === '11') {
    $(`select[name='profiles[${moduleId}]']`).show();
  } else {
    $(`select[name='profiles[${moduleId}]']`).hide();
  }

  if (typeId === '9') {
    $(`select[name='batteries[${moduleId}]']`).next('.select2-container').show();
  } else {
    $(`select[name='batteries[${moduleId}]']`).next('.select2-container').hide();
  }
})
