import $ from 'jquery'
import Module from './Module'
import Assessment from './Assessment'
import ActiveEndDate from './ActiveEndDate'

class CycleAPCreate {
  load () {
    ActiveEndDate.load()
    Module.load('#cycle_ap_modules_')
    Assessment.load()
  }
}

const cycleCreate = new CycleAPCreate()

$(function () {
  if ($('#cycle-ap-create, #cycle-ap-edit').length > 0) {
    cycleCreate.load()
  }
})
