import $ from 'jquery'

export default class Module {
  static load (name) {
    $('#select-modules').multiSelect({
      afterSelect: function (value) {
        const $container = $('#module-managing')
        let newWidget = $container.attr('data-prototype')
        newWidget = newWidget.replace(/__name__/g, value)
        $container.append(newWidget)
      },
      afterDeselect: function (value) {
        $(name + value).remove()
      },
      cssClass: 'select-modules',
      keepOrder: true,
      selectableHeader: '<div>Selectable</div>',
      selectionHeader: '<div>Selected</div>'
    })
  }
}
