/* eslint eqeqeq: 0 */
import $ from 'jquery'
import tinymce from 'tinymce'

$(function () {
  if ($('#sb-contact-form').length) {
    loadTemplateContent()
  }
})

export function togglePreview () {
  if ($('#previewButton').val() == 'preview') {
    replaceVariables($('#subject').val(), tinymce.activeEditor.getContent(), function (subject, body) {
      fillPreview(subject, body)
      togglePreviewFields()
      $('#previewButton').val('edit')
      $('#previewButton').html('<i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit')
    })
  } else {
    $('#previewButton').val('preview')
    $('#previewButton').html('<i class="fa fa-search-plus" aria-hidden="true"></i> Preview')
    togglePreviewFields()
  }
}

export function loadTemplateContent () {
  const id = $('select[name=template]').val()

  if (typeof id !== 'undefined') {
    retrieveTemplate(id, function (template) {
      $('input[name="subject"]').val(template.subject)
      tinymce.activeEditor.setContent(template.template)
    })
  }
}

function retrieveTemplate (id, callback) {
  $.get('/api/v1/templates/' + id, function (response) {
    callback(response)
  })
}

function togglePreviewFields () {
  $('#body').parent().fadeToggle(0)
  $('#subject').parent().fadeToggle(0)
  $('#preview').fadeToggle(0)
  $('#select-template').fadeToggle(0)
  $('button[type=submit]').fadeToggle(0)
  $('#button-placeholders').fadeToggle(0)
}

function fillPreview (subject, body) {
  $('#previewSubject').html(subject)
  $('input[name=previewSubject]').val(subject)
  $('#previewBody').html(body)
  $('input[name=previewBody]').val(body)
}

function replaceVariables (subject, body, callback) {
  const cycleId = $('input[name="cycleId"]').val()
  let url = ''

  if (typeof cycleId !== 'undefined') {
    url = '/api/testenv/templates/replace/cycle/' + cycleId + '/candidate/' + $('input[name="recipients[]"]').first().val() + '/facilitator/' + $('input[name="facilitators[]"]').first().val()
  } else {
    url = '/api/testenv/templates/replace/candidate/' + $('input[name="recipients[]"]').first().val() + '/facilitator/' + $('input[name="facilitators[]"]').first().val()
  }

  $.post(url, { subject }, function (subjectReplaced) {
    $.post(url, { body }, function (bodyReplaced) {
      callback(subjectReplaced, bodyReplaced)
    })
  })
}
