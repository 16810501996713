/* eslint no-var: 0 */
import $ from 'jquery'
import videojs from 'video.js/dist/video.min.js'

$(document).ready(function () {
  if ($('.video-js').length) {
    $('.video-js').each(function () {
      var progress = $(this).parent().parent().find('.vimeo-time')
      var total = $(this).parent().parent().find('.vimeo-total')
      var container = $(this).closest('.vimeo-container')
      var button = $(this).parent().parent().find('.vimeo-play-button')
      var questionSection = getQuestionSection($(this))

      if (container.attr('type') === '2') {
        container.css('display', 'inline-block')
      }

      var player = videojs(this, {
        preload: 'auto'
      }, function () {
        this.on('timeupdate', function () {
          var seconds = player.currentTime()
          progress.html(formatTime(seconds))
          total.html(formatTime(player.duration()))
        })

        this.on('ended', function () {
          var $form = $('div.assessment-page-wrapper form')
          var autosubmit = $form.find('input[name=autosubmit').val()

          if (autosubmit) {
            $form.submit()
          } else {
            var continueButton = getContinueButton(questionSection)
            $(continueButton).val('Continue')
            $(continueButton).css('display', 'block')
            playButtonReady(button, progress)
          }
        })

        this.on('loadedmetadata', function () {
          total.html(formatTime(player.duration()))
        })
      })
    })
  }
})

$(document).on('click', '.vimeo-play-button', function () {
  var button = $(this)
  var player = $(this).parent().parent().find('.vimeo-container').find('video')[0]

  togglePlay(button, player)
})

function getQuestionSection (videojs) {
  var container = videojs.parent()
  var question = container.parent()
  var section = question.parent()
  return section
}

function getContinueButton (questionSection) {
  var questionBlock = questionSection.parent()
  var button = questionBlock.parent().next('.nextpage')
  return button
}

function playButtonReady (button, progress) {
  button.attr('value', 'Play')
  button.html("<i class='fa fa-play'></i>")
  progress.html('00:00')
}

function formatTime (curtime) {
  var minutes = pad(Math.floor(curtime / 60), 2)
  var seconds = pad(Math.floor(curtime - minutes * 60), 2)

  return minutes + ':' + seconds
}

function pad (str, max) {
  str = str.toString()
  return str.length < max ? pad('0' + str, max) : str
}

function togglePlay (button, player) {
  var status = button.attr('value')

  switch (status) {
    case 'Play':
      button.attr('value', 'Pause')
      button.html("<i class='fa fa-pause'></i>")
      player.play()
      break
    case 'Pause':
      button.attr('value', 'Play')
      button.html("<i class='fa fa-play'></i>")
      player.pause()
      break
  }
}
