import axios from 'axios'

export function getDefaultStatusSets () {
  return axios({ method: 'get', url: '/api/v1/statuses/sets/default' })
    .then(response => response.data)
    .catch((err) => {
      console.error(err)
      throw err
    })
}

export function getStatusSets (params) {
  return axios({
    method: 'get',
    url: '/api/v1/statuses/sets',
    params: params
  })
    .then(response => response.data)
    .catch((err) => {
      console.error(err)
      throw err
    })
}

export function getClientStatusSets (id) {
  return axios({ method: 'get', url: `/api/v1/statuses/sets/clients/${id}` })
    .then(response => response.data)
    .catch((err) => {
      console.error(err)
      throw err
    })
}

export function getStatusSet (id) {
  return fetch(`api/v1/statuses/sets/${id}`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then(data => data)
    })
}

export function createStatusSet (json) {
  return axios({
    method: 'post',
    url: '/api/v1/statuses/sets/create',
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function deleteStatusSet (id) {
  return axios({
    method: 'delete',
    url: `/api/v1/statuses/sets/${id}`
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function editStatusSet (id, json) {
  return axios({
    method: 'post',
    url: `/api/v1/statuses/sets/${id}/edit`,
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getStatusTiers () {
  return axios({
    method: 'get',
    url: '/api/v1/statuses/tiers'
  })
    .then(response => response.data)
    .catch(err => {
      console.log(err)
      throw err
    })
}
