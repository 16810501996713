import { Chart } from 'chart.js/auto';

export default function BigFiveChart (ctx, data) {
  data.scores = data.scores.map(Math.round)

  return new Chart(ctx, {
    type: 'radar',
    data: {
      labels: data.names,
      datasets: [{
        label: 'Respondent Zoning',
        data: data.scores,
        backgroundColor: 'rgba(101, 69, 160, .5)',
        borderColor: 'rgba(104, 85, 164, 1)',
        fill: false,
        borderWidth: 3,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointBackgroundColor: 'rgba(101, 69, 160, .5)',
        pointBorderColor: 'rgba(101, 69, 160, 1)'
      },
      {
        label: 'Ideal Zoning',
        data: data.ends,
        fill: '+1',
        backgroundColor: 'rgba(0, 255, 0, .25)',
        borderColor: 'rgba(0, 153, 0, .5)',
        pointRadius: 0,
        pointHoverRadius: 0
      }, {
        label: 'Ideal Zoning',
        data: data.starts,
        backgroundColor: 'rgba(0, 255, 0, .25)',
        fill: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderColor: 'rgba(0, 153, 0, .5)'
      }]
    },
    options: {
      plugins: {
        legend: { display: false },
        point: {
          radius: 50,
          hoverRadius: 15,
          borderWidth: 2
        }
      },
      scales: {
        r: {
          ticks: {
            display: false
          }
        }
      }
    }
  })
}
