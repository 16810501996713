import $ from 'jquery'
import tinymce from 'tinymce'

export function formatPercentileScore (score) {
  const val = Math.round(score)

  if (val > 99) {
    return 99
  } else if (val < 1) {
    return 1
  }

  return val
}

export function convertHashToQuery (url) {
  if (url.indexOf('#') < 0) {
    return '?'
  }
  const hashPart = url.substring(url.indexOf('#') + 1)
  return '?' + hashPart
}

export function setEmailNotification (cycle, elem) {
  setLoadingIcon(elem, cycle)
  $.post('api/v1/email-notifications/cycles/' + cycle, { frequency: $(elem).find('select').val() }, function (result) {
    removeLoadingIcon(elem, cycle, !!result.error)
  })
}

function setLoadingIcon (elem, cycle) {
  $("<i style='color:steelblue' id='loading" + cycle + "' class='fa fa-spinner fa-2x fa-spin fa-fw'></i>").insertAfter(elem)
  $(elem).hide()
}

function removeLoadingIcon (elem, cycle, error) {
  $('#loading' + cycle).remove()
  if (error) {
    $("<span style='color: orangered'><i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Error</span>").insertAfter(elem)
  } else {
    $(elem).show()
  }
}

$(document).on('click', '#skillbuilder-cycle-competency-view .competency-hidden-check', function () {
  const competency = {}
  competency.id = $(this).attr('competency-id')
  competency.cycleId = $(this).attr('cycle-id')
  competency.hidden = $(this).is(':checked')
  updateCompetency(competency)
})

$(document).on('click', '.testprep-get-link', function () {
  const route = $(this).data('route')
  const modal = $('.reveal-modal')

  modal.html("<div class='row'>\n" +
    "        <div class='small-12 columns'>\n" +
    '            Copy Link URL:\n' +
    "            <textarea style='height: 120px'>" + route + '</textarea>\n' +
    '        </div>\n' +
    "    </div><div class='row'>\n" +
    "        <div class='small-12 columns'>\n" +
    '            Copy Link HTML:\n' +
    "            <textarea style='height: 120px'><a href='" + route + "'>Begin Testprep</a></textarea>\n" +
    '        </div>\n' +
    '    </div>\n' +
    "    <a class='close-reveal-modal action-icon' aria-label='Close'>&#215;</a>")

  modal.foundation('reveal', 'open')
})

$(document).ready(function () {
  let cycleTablesShow = false
  let orderShown = false

  $('#skillbuilder-cycle-competency-view #orderCompetency').click(function () {
    if (orderShown) {
      $('#skillbuilder-cycle-competency-view .order').hide()
      orderShown = false
    } else {
      $('#skillbuilder-cycle-competency-view .order').show()
      orderShown = true
    }
  })

  $('.ap-cycle-select').on('change', function () {
    updateDropdownApModules($('#ap-cycle-module-select-' + $(this).attr('data-module')).first(), $(this).find(':selected').val())
    updateDropdownApModuleInfos($('#ap-cycle-module-info-select-' + $(this).attr('data-module')).first(), $(this).find(':selected').val())
  })

  $('#toggle-cycle-tables').click(function () {
    if (cycleTablesShow === true) {
      $(this).text('Show ' + $('#client-name').val() + ' cycles')
      $('#cycle-tables').css('display', 'none')
      cycleTablesShow = false
    } else {
      $(this).text('Hide ' + $('#client-name').val() + ' cycles')
      $('#cycle-tables').css('display', 'block')
      cycleTablesShow = true
    }
  })

  $('.modify-applicant-statuses-btn').on('click', function () {
    $('#modify-app-statuses-modal').foundation('reveal', 'open')
  })

  $('#no-hires-from-cycle-btn').on('click', function () {
    $('div#prompt-for-hires').fadeOut(250, function () {
      $('div#reason-for-no-hires').fadeIn(250)
    })
  })

  $('#cancel-no-hires-btn').on('click', function () {
    $('div#reason-for-no-hires').fadeOut(250, function () {
      $('div#prompt-for-hires').fadeIn(250)
    })
  })

  $(document).on('click', '#rezview-modal a.close-sub-modal', function () {
    $('#modify-app-statuses-modal').foundation('reveal', 'open')
  })

  $('input[name="search-applicants"]').on('keyup', function () {
    searchList($(this).val(), 'ul.applicant-list')
  })

  $(document).on('click', '#select-all-apps', function () {
    $('ul.applicant-list input').prop('checked', $(this).prop('checked'))
  })

  $(document).on('click', '#modify-app-statuses-modal td[data-column="status"] > span.status-cell-value', function () {
    const isGrantedEdit = $('#detail-view').attr('data-is-granted-edit')

    if (isGrantedEdit) {
      const id = $(this).parents('tr').find('td[data-column="a.id"]').text()
      let statusSelect = '<select name="applicantStatus" data-appid="' + id + '"></select>'
      const statuses = JSON.parse($('input[name="cycle-statuses"]').val())
      const currentStatus = $(this).text()
      for (const status of statuses) {
        let statusOption = '<option value="' + status.id + '">' + status.value + '</option>'
        if (status.value === currentStatus) {
          statusOption = $(statusOption).prop('selected', true)
        }
        statusSelect = $(statusSelect).append(statusOption)
      }
      $(this).replaceWith(statusSelect)
    }
  })

  $(document).on('change', 'select[name="applicantStatus"]', function () {
    const appId = $(this).attr('data-appid')
    const val = $(this).find('option:selected').val()
    if ($('input[name="applicants[' + appId + ']"]').length > 0) {
      $('input[name="applicants[' + appId + ']"]').val(val)
    } else {
      $('form[name="changed-applicant-status-form"]').append('<input type="hidden" name="applicants[' + appId + ']" value="' + val + '">')
    }
  })

  $(document).on('blur', 'select[name="applicantStatus"]', function () {
    $(this).replaceWith('<span class="status-cell-value status-cell-editable">' + $(this).find('option:selected').text() + '</span>')
  })

  $('#cancel-modify-status-btn').on('click', function () {
    $('#modify-app-statuses-modal').foundation('reveal', 'close')
  })

  $('a#confirm-modify-status-btn').on('click', function () {
    $('#cancel-modify-status-btn').trigger('click')
    $('form[name="changed-applicant-status-form"]').submit()
  })

  $('a#view-applicants-btn').on('click', function () {
    $('#courtesy-letters-modal').foundation('reveal', 'open')
  })

  $('a#confirm-courtesy-letters-btn').on('click', function () {
    $('#courtesy-letters-modal').foundation('reveal', 'close')
  })

  $('a#cancel-courtesy-letters-btn').on('click', function () {
    $('input[name="receive-courtesy-letter"]:not(:checked)').prop('checked', true)
    $('form[name="close-cycle-form"]').find('input[name^="no-courtesy-letter"]').remove()
    $('#courtesy-letters-modal').foundation('reveal', 'close')
  })

  $('#good-feedback').on('click', function () {
    $('#bad-feedback').removeClass('chosen')
    $(this).addClass('chosen')
    $('#feedback-sentence #bad').slideUp(150, function () {
      $('#feedback-sentence #good').slideDown(250)
    })
    $('form[name="close-cycle-form"] > input[name="feedback[response]"]').val(1)
  })

  $('#bad-feedback').on('click', function () {
    $('#good-feedback').removeClass('chosen')
    $(this).addClass('chosen')
    $('#feedback-sentence #good').slideUp(150, function () {
      $('#feedback-sentence #bad').slideDown(250)
    })
    $('form[name="close-cycle-form"] > input[name="feedback[response]"]').val(0)
  })

  $('a#close-cycle-btn').on('click', function () {
    const $form = $('form[name="close-cycle-form"]')
    const $hiringManagerData = $('table#hiring-manager-table input[name^="hiring_manager"]')

    $hiringManagerData.each(function (index, element) {
      const newElement = $(element).clone()
      $(newElement).attr('type', 'hidden')
      $form.append(newElement)
    })

    const $hiredApplicantData = $('table#hired-applicants-table input[name^="hired"]')
    let cont = true
    $hiredApplicantData.each(function (index, element) {
      if ($(element).attr('name').includes('hire_date') && !$(element).val().length > 0) {
        $(element).addClass('invalid')
        cont = false
      }
      if (cont) {
        const newElement = $(element).clone()
        $(newElement).attr('type', 'hidden')
        $form.append(newElement)
      }
    })

    if ($('input[name="no-hires-reason"]').length > 0) {
      $form.append('<input type="hidden" name="hired" value="' + $('input[name="no-hires-reason"]').val() + '">')
    }

    if (!cont) {
      alert('Please add hire dates to all hired applicants')
    } else {
      if ($('textarea[name="feedback-comments"]').val().length > 0) {
        $form.find('input[name="feedback[comments]"]').val($('textarea[name="feedback-comments"]').val())
      }
      if ($('input[name="receive-courtesy-letter"]:checked').length > 0) {
        const templateBody = tinymce.get('template-body').getContent()
        const base64TemplateBody = templateBody

        $form.append('<input name="courtesy-letters[template]" type="hidden" value="' + $('select#template-dropdown').val() + '">')
        $form.append('<input name="courtesy-letters[subject]" type="hidden" value="' + $('input#template-subject').val() + '">')
        $form.append('<input name="courtesy-letters[body]" type="hidden" value="' + base64TemplateBody + '">')

        $('input[name="receive-courtesy-letter"]:checked').each(function (index, element) {
          $form.append('<input name="courtesy-letters[recipients][' + index + ']" type="hidden" value="' + $(element).attr('data-id') + '">')
        })
      }

      $form.submit()
    }
  })

  if ($(document).find('[data-pendingstatuses]').length > 0 && $(document).find('input[name="allow-pending"]').prop('checked') !== true) {
    $(document).find('[data-pendingstatuses]').parents('.reveal-modal').foundation('reveal', 'open')
  }
})

function searchList (searchTerm, listId) {
  if (searchTerm.length > 0) {
    const items = $(listId).find('li')

    $(items).filter(function () {
      return $(this).text().trim().toLowerCase().indexOf(searchTerm.toLowerCase()) < 0
    }).addClass('search-filtered')
  } else {
    $('.search-filtered').removeClass('search-filtered')
  }
}

function updateCompetency (competency) {
  $.ajax({
    url: '/api/v1/competencies/' + competency.id + '/cycles/' + competency.cycleId,
    type: 'PUT',
    dataType: 'json',
    data: competency,
    success: function () {
    }
  }).fail(function () {
    alert('There was an error saving the competency data.')
  })
}

function updateDropdownApModules (select, cycleId) {
  select.prop('disabled', true)
  select.find('option').remove()

  if (cycleId !== '0') {
    $.get('api/v1/cycles/' + cycleId + '/modules', function (response) {
      const modules = response.data

      select.append($('<option></option>')
        .attr('value', 0)
        .text('Select Module'))

      $.each(modules, function (key, value) {
        select.append($('<option></option>')
          .attr('value', value.id)
          .text(value.name))
      })

      select.prop('disabled', false)
    })
  }
}

function updateDropdownApModuleInfos (select, cycleId) {
  select.prop('disabled', true)
  select.find('option').remove()

  if (cycleId !== '0') {
    $.get('api/v1/cycles/' + cycleId + '/module-infos', function (response) {
      const moduleInfos = response.items

      select.append($('<option></option>')
        .attr('value', 0)
        .text('No link'))

      $.each(moduleInfos, function (key, value) {
        select.append($('<option></option>')
          .attr('value', value.id)
          .text(value.module.name))
      })

      select.prop('disabled', false)
    })
  }
}
