import $ from 'jquery'

export default class Password {
  validatePassword () {
    const passwords = $('input[type="password"]')

    return !(this._passwordsAreRequired(passwords) && (!this._passwordsHaveProperLength(passwords) || !this._passwordsMatch(passwords)))
  }

  _passwordsAreRequired (passwords) {
    return !passwords.hasClass('no-required')
  }

  _passwordsHaveProperLength (passwords) {
    if (passwords.get(0).value.length >= 8 && passwords.get(1).value.length >= 8) {
      return true
    }

    alert('Your password must be at least 8 characters long!')
    return false
  }

  _passwordsMatch (passwords) {
    if (passwords.get(0).value === passwords.get(1).value) {
      return true
    }

    alert('Passwords don\'t match!')
    return false
  }
}
