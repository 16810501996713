import $ from 'jquery'
import tinymce from 'tinymce'

const requestCycleWidget = {
  init () {
    this.bindUIActions()
  },

  bindUIActions () {
    $(document).on('click', '.create-cycle-button', function () {
      $('#contact-pm-modal').foundation('reveal', 'open')

      tinymce.remove('textarea#client_create_cycle_job_posting')
      tinymce.init({
        branding: false,
        convert_urls: false,
        element_path: false,
        entity_encoding: 'raw',
        height: 300,
        selector: 'textarea#client_create_cycle_job_posting',
        theme: 'silver',
        plugins: 'searchreplace autolink directionality visualblocks visualchars image link media charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern',
        toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
        fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
        setup: function (ed) {
          ed.on('init', function () {
            ed.execCommand('fontSize', false, '11pt')
          })
        }
      })
    })
  }
}

$(document).ready(function () {
  if ($('#request-cycle-wrapper').length > 0) {
    requestCycleWidget.init()
  }
})
