import $ from 'jquery'
import CycleUser from './CycleUser'

class HiringProgramCycleCreate {
  load () {
    CycleUser.load('cycle')
  }
}

const cycleCreate = new HiringProgramCycleCreate()

$(function () {
  if ($('#hiring-program-cycle-create').length > 0) {
    cycleCreate.load()
  }
})
