/* eslint-disable no-unused-vars */
/* eslint no-var: 0 */
/* eslint eqeqeq: 0 */
import $ from 'jquery'
import Filter from './modules/Filter'
import { findModal, showFlagModal, showRedactedRezviewModal, showRezviewModal } from './applicant'
import { setEmailNotification } from './cycle'
import Password from './modules/Password'
import ProgressChart from './charts/skillbuilder/ProgressChart'
import ApplicantCompletionChart from './charts/ApplicantCompletionChart'
import ApplicantStatusChart from './charts/ApplicantStatusChart'
import BulletChart, { getChartData, parseZoneString } from './charts/BulletChart'

const filter = new Filter()

$(function () {
  $('.custom-top-bar .menu-open').click(function () {
    window.menuAPI.open()
  })

  $('.select2-jobs').select2()

  $('.select2-job-categories').select2({
    placeholder: 'Select Categories',
    width: '100%'
  })

  $('.generic-job-input').select2({
    placeholder: 'Select a generic job',
    width: '100%'
  })

  $('.js-job-select').select2({ width: '300px' })

  $('.portal_legacy_expand_subscores').click(function () {
    const subscoreClass = $(this).attr('data-subscore_class')
    $(subscoreClass).show()
    $(this).hide()
  })

  $('.portal_legacy_contract_subscores').click(function () {
    const subscoreClass = $(this).attr('data-subscore_class')
    $(subscoreClass).hide()
    $(subscoreClass).each(function () {
      const $expandBottom = $(this).find('div.portal_legacy_expand_subscores')
      $($expandBottom.attr('data-subscore_class')).hide()
      $expandBottom.show()
    })
    $('.portal_legacy_expand_subscores[data-subscore_class="' + subscoreClass + '"]').show()
  })

  $('.location-header-row-inactive').hide()

  if (typeof (sessionStorage.getItem('showing-type-0')) === 'undefined' || sessionStorage.getItem('showing-type-0') === null) {
    const cycleStatus = $('.location-header-type').val()

    if (cycleStatus === '-3') {
      $('.location-header-row-inactive-0').show()
      $('.location-header-row-active-0').show()
    } else if (cycleStatus === '-2') {
      $('.location-header-row-inactive-0').hide()
      $('.location-header-row-active-0').show()
    } else if (cycleStatus === '-1') {
      $('.location-header-row-active-0').hide()
      $('.location-header-row-inactive-0').show()
    }
  }

  if (typeof (sessionStorage.getItem('showing-type-2')) === 'undefined' || sessionStorage.getItem('showing-type-2') === null) {
    const cycleStatus = $('.location-header-type').val()

    if (cycleStatus === '-3') {
      $('.location-header-row-inactive-2').show()
      $('.location-header-row-active-2').show()
    } else if (cycleStatus === '-2') {
      $('.location-header-row-inactive-2').hide()
      $('.location-header-row-active-2').show()
    } else if (cycleStatus === '-1') {
      $('.location-header-row-active-2').hide()
      $('.location-header-row-inactive-2').show()
    }
  }

  $('.location-header-type').change(function () {
    const type = $(this).data('cycle-type')

    sessionStorage.setItem('showing-type-' + type, $(this).val())

    if ($(this).val() === '-3') {
      $('.location-header-row-inactive-' + type).show()
      $('.location-header-row-active-' + type).show()
    } else if ($(this).val() === '-2') {
      $('.location-header-row-inactive-' + type).hide()
      $('.location-header-row-active-' + type).show()
    } else if ($(this).val() === '-1') {
      $('.location-header-row-active-' + type).hide()
      $('.location-header-row-inactive-' + type).show()
    }
  })

  if (typeof (sessionStorage.getItem('showing-type-0')) !== 'undefined' && sessionStorage.getItem('showing-type-0') !== null) {
    $('.location-header-type[data-cycle-type=0]').val(sessionStorage.getItem('showing-type-0'))
    $('.location-header-type[data-cycle-type=0]').trigger('change')
  }

  if (typeof (sessionStorage.getItem('showing-type-2')) !== 'undefined' && sessionStorage.getItem('showing-type-2') !== null) {
    $('.location-header-type[data-cycle-type=2]').val(sessionStorage.getItem('showing-type-2'))
    $('.location-header-type[data-cycle-type=2]').trigger('change')
  }

  $('#invite_assessor_candidates').attr('multiple', 'multiple').select2({
    placeholder: 'Select candidates',
    allowClear: true,
    width: '100%'
  })

  showOrderSurveyEdit($('#pap_surveys_table'))

  $('#add_pap_survey').click(function () {
    const papSurveyEditTemplate = $('.pap_survey_edit_template').html()
    $('#pap_surveys_table').append(papSurveyEditTemplate)
    showOrderSurveyEdit($('#pap_surveys_table'))
  })

  $('#pap_surveys_table').sortable({
    helper: fixHelperSurveyEdit,
    stop: function () { showOrderSurveyEdit($(this)) }
  })

  let skillbuilderExportCounter = 0
  const skillbuilderExportStep = 100

  $('.button-container').each(function () {
    skillbuilderExportCounter += skillbuilderExportStep
    $(this).animate({
      opacity: '1'
    }, skillbuilderExportCounter)
  })

  if ($('.filter-bar').length > 0) {
    filter.initFilters()
  }

  jQuery.expr[':'].icontains = function (a, i, m) {
    return jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0
  }

  $('.proctor-home-applicant-search').bind('change keyup input', function () {
    if (this.value === '') {
      $('.proctor-home-applicant-row').show()
    } else {
      $('.proctor-home-applicant-row').hide()
      $('.proctor-home-applicant-search-element:icontains(' + this.value + ')').parent().show()
    }
  })

  $('.searchable-select').select2({
    width: '100%'
  })

  $('#delete-applicant-wrapper input:radio').change(function () {
    const $submit = $('#delete-applicant-wrapper #submit-button')

    if (this.value === 'delete') {
      $submit.addClass('alert')
      $submit.text('Delete')
    } else {
      $submit.removeClass('alert')
      $submit.text('Refresh')
    }
  })

  $('#delete-applicant-wrapper .panel').click(function () {
    $(this).find('input:radio').prop('checked', true)
    $(this).find('input:radio').change()
  })

  $(document).on('change', 'table.data input.module-type-item', function () {
    const parent = $(this).parents('.module-type-selector')
    const len = $(parent).find('input:checked').length
    $(parent).find('span.selected-count').html(len)
  })

  $(document).on('change', '.select-notification', function () {
    setEmailNotification($(this).attr('data-cycle-id'), this)
  })

  $(document).on('click', 'td[data-column="c.notifications"]', function (e) {
    e.stopPropagation()
  })

  $(document).on('click', '.clickable-table-row', function () {
    window.location = $(this).attr('data-redirect-url')
  })

  if (document.getElementById('location-breakdown-graph')) {
    const locationCtx = document.getElementById('location-breakdown-graph')
    const data = JSON.parse(locationCtx.dataset.values)
    const locationProgressChart = new ProgressChart(locationCtx, data)
  }
  if (document.getElementById('assessment-breakdown-graph')) {
    const assessmentCtx = document.getElementById('assessment-breakdown-graph')
    const data = JSON.parse(assessmentCtx.dataset.values)
    const assessmentProgressChart = new ProgressChart(assessmentCtx, data)
  }
  if (document.getElementById('cycle-breakdown-graph')) {
    const cycleCtx = document.getElementById('cycle-breakdown-graph')
    console.log(cycleCtx.dataset.values)
    const data = JSON.parse(cycleCtx.dataset.values)
    console.log(data)
    const cycleProgressChart = new ProgressChart(cycleCtx, data)
  }

  if (document.getElementById('applicant-completion-chart')) {
    const applicantCompletionCtx = document.getElementById('applicant-completion-chart')
    const applicantCompletionChart = new ApplicantCompletionChart(applicantCompletionCtx)
  }

  if (document.getElementById('applicant-status-chart')) {
    const applicantStatusCtx = document.getElementById('applicant-status-chart')
    const applicantStatusChart = new ApplicantStatusChart(applicantStatusCtx)
  }

  if (document.querySelectorAll('canvas.bulletchart')?.length) {
    for (const ctx of document.querySelectorAll('canvas.bulletchart')) {
      const data = getChartData(parseZoneString(ctx.dataset.values), '', ctx.dataset.score)
      const chart = new BulletChart(ctx, data)
    }
  }
})

$(document).on('click', '.pap-survey-delete', function () {
  $(this).closest('tr').remove()
  showOrderSurveyEdit($('#pap_surveys_table'))
})

$(document).on('click', '.process-delete-assessment', function () {
  const index = $(this).parents('.container').find('.assessment-order').val()
  $('#delete-modal').attr('data-index', index).foundation('reveal', 'open')
})

$(document).on('click', 'button.saveNotes', function (ev) {
  const route = $(ev.currentTarget).attr('data-href')
  const notes = $(ev.currentTarget).parents('td').find('textarea').val()
  const msgEl = $(ev.currentTarget).parents('td').find('span.message')

  $.post(route, { notes }, function () {
    msgEl.css('opacity', 1).show().html('Successfully saved!')

    setTimeout(function () {
      msgEl.animate({ opacity: 0 }, {
        complete () {
          msgEl.hide()
        }
      })
    }, 2000)
  })
})

$(document).on('click', '.active-remove-filter', function () {
  filter.removeFilterEvent(this)
})

$(document).on('change', '.skillbuilder-home select', function () {
  $('#searchForm').submit()
})

$(document).on('click', '.summary', function () {
  showRezviewModal($(this).parents('tr').data('rowId'))
})

$(document).on('click', '.redacted-summary', function () {
  showRedactedRezviewModal($(this).parents('tr').data('rowId'))
})

$(document).on('click', 'span.flag', function () {
  showFlagModal($(this).parents('tr').data('rowId'))
})

$(document).on('click', 'a.cycle-detail-button', function () {
  const url = window.location.href
  window.location = url.replace(/edit\/applicants(\/[0-9]*)?/, 'results')
})

var fixHelperSurveyEdit = function (e, ui) {
  ui.children().each(function () {
    $(this).width($(this).width())
  })
  return ui
}

function showOrderSurveyEdit (ctx) {
  let ct = 1
  $('.order', ctx).each(function () {
    $(this).html(ct)
    ct++
  })
}

$(document).on('submit', 'form[name="reset_password"]', function () {
  const p = new Password()
  return p.validatePassword()
})

$(document).on('click', '.response-number-modal', function () {
  const modal = findModal()
  const responses = JSON.parse($(this).attr('data-responses'))
  let html = '<ul>'

  for (let i = 0; i < responses.length; i++) {
    html += `<li>${responses[i].assessment_assignment.cycle_assignment.user.account.first_name} ${responses[i].assessment_assignment.cycle_assignment.user.account.last_name}</li>`
  }

  html += '</ul>'

  modal.html(html)
  modal.foundation('reveal', 'open')
})
