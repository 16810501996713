import { Chart } from 'chart.js/auto';

export default function ApplicantCompletionChart (ctx) {
  const labels = JSON.parse(ctx.dataset.labels)
  const data = JSON.parse(ctx.dataset.values)
  const max = JSON.parse(ctx.dataset.max)
  return new Chart(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        label: 'Completed by',
        data: data,
        backgroundColor: [
          'rgba(76, 152, 225, 0.6)'
        ],
        borderColor: [
          'rgba(76, 152, 225, 1)'
        ],
        borderWidth: 1,
        lineTension: 0,
        fill: true
      }]
    },
    options: {
      legend: {
        display: false
      },
      scales: {
        y: {
          max: max,
          min: 0,
          grid: {
            drawTicks: true
          },
          ticks: {
            autoSkip: false
          }
        },
        x: {
          ticks: {
            autoSkip: false
          }
        }
      },
      plugins: {
        tooltips: {
          displayColors: false
        }
      }
    }
  });
}
