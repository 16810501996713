import $ from 'jquery'

export default class CycleUser {
  static load (formName = 'cycle_hiring') {
    const $wrapper = $('.user-access-input')

    $(document).on('organization:change', function () {
      $wrapper.html('')
    })

    $(document).on('location:change', function (event, locationId) {
      if (locationId !== '') {
        $.get(
          '/api/v1/locations/' + locationId + '/users',
          function (data) {
            if (data.data.length > 0) {
              $wrapper.html('')

              $.each(data.data, function (id, user) {
                $wrapper.append('<div class="checkbox"><label><input type="checkbox" id="cycle_hiring_users_' + user.id + '" name="' + formName + '[users][]" value="' + user.id + '"> ' + user.account.first_name + ' ' + user.account.last_name + '</label></div>')
              })
            } else {
              $wrapper.html('')
            }
          },
          'json'
        )
      } else {
        $wrapper.html('')
      }
    })

    $(document).on('change', 'input.location-access-select-all', function () {
      $('div.user-access-input input').prop('checked', $(this).prop('checked'))
    })
  }
}
