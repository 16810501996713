import { showNotification } from '@mantine/notifications'
import axios from 'axios'

const endpoint = '/api/v1/cycles'

export function getCycles (params) {
  return axios({
    method: 'get',
    url: `${endpoint}`,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getCycleStatuses (id) {
  return fetch(`${endpoint}/${id}/status`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then((data) => data)
    })
}

export function getCycleTemplates (id, filterFunction = null) {
  return fetch(`${endpoint}/${id}/templates`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then(data => {
        if (data.data && filterFunction) {
          data.data = data.data.filter(filterFunction)
        }
        return data
      })
    })
}

export function getCycleTextTemplates (id) {
  return fetch(`${endpoint}/${id}/text-templates`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json()
    })
}

export function getCycleModules (id) {
  return fetch(`${endpoint}/${id}/modules`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json()
    })
}

export function getCycleBatteryLastSubmissions (cycleId, batteryId) {
  return fetch(`${endpoint}/${cycleId}/batteries/${batteryId}/last-submissions`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then((data) => data)
    })
}

export function getCycleApplicants (id, status, courtesyLetter) {
  return fetch(`${endpoint}/${id}/applicants?limit=-1` + (status !== '' ? `&status_names[]=${status}` : '') + (courtesyLetter ? '&courtesy_sent' : ''), {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then((data) => data)
    })
}

export function isUserSubscribed (cycleId) {
  return axios({
    method: 'get',
    url: `${endpoint}/${cycleId}/isUserSubscribed`
  })
    .then(function (response) {
      return response.data === 1
    })
    .catch(function (error) {
      console.error(error)
      return false
    })
}

export function subscribe (id) {
  return axios({
    method: 'post',
    url: `${endpoint}/${id}/subscribe`
  })
    .then(() => true)
    .catch(function (error) {
      console.error(error)
      throw error
    })
}

export function unsubscribe (id) {
  return axios({
    method: 'post',
    url: `${endpoint}/${id}/unsubscribe`
  })
    .then(() => true)
    .catch(function (error) {
      console.error(error)
      throw error
    })
}

export function getComments (id, params) {
  return axios({
    method: 'get',
    url: `${endpoint}/${id}/comments`,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getFiles (id, params) {
  return axios({
    method: 'get',
    url: `${endpoint}/${id}/files`,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function createFile (id, data) {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('description', data.description)

  data.tags.map(tag => formData.append('tags[]', tag))

  return axios({
    method: 'post',
    url: `${endpoint}/${id}/files`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function pushToIcims (id) {
  return axios({
    method: 'post',
    url: `api/v1/icims/${id}/push`
  })
    .then(response => {
      showNotification({ message: 'Successfully started pushing to iCIMS!', color: 'green' })
      console.log(response)
    })
}

export function getUsersWithAccess (id) {
  return axios({
    method: 'get',
    url: `${endpoint}/${id}/usersWithAccess`
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
