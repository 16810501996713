import axios from 'axios'

const endpoint = '/api/v1/hiring-views'

export function getHiringViewDetails (id) {
  return fetch(`${endpoint}/${id}/details`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json()
    })
}

export function getHiringViewTemplates (id, filterFunction = null) {
  return fetch(`${endpoint}/${id}/templates`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then(data => {
        if (data.data && filterFunction) {
          data.data = data.data.filter(filterFunction)
        }
        return data
      })
    })
}

export function getHiringViewTextTemplates (id) {
  return fetch(`${endpoint}/${id}/text-templates`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json()
    })
}

export function getHiringViewBatteries (id) {
  return fetch(`${endpoint}/${id}/batteries`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then((data) => data)
    })
}

export function getHiringViewApplicants (id, status, courtesyLetter) {
  return fetch(`${endpoint}/${id}/applicants?limit=-1` + (status !== '' ? `&status_names[]=${status}` : '') + (courtesyLetter ? '&courtesy_sent' : ''), {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then((data) => data)
    })
}

export function getHiringViewBatteriesLastSubmissions (hiringViewId, batteryId) {
  return fetch(`${endpoint}/${hiringViewId}/batteries/${batteryId}/last-submissions`, {
    credentials: 'same-origin'
  })
    .then(response => {
      return response.json().then((data) => data)
    })
}

export function getComments (id, params) {
  return axios({
    method: 'get',
    url: `/api/v1/hiring-views/${id}/comments`,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
