/* eslint no-var: 0 */
import $ from 'jquery'

$(function () {
  let AssessorWidgetConfig
  var AssessorWidget = {
    settings: {
      assessors: $('#assessor-list'),
      api: {
        assessors: $('#assessor-widget').attr('data-stage-progress-id')
          ? '/your/invites/' + $('#assessor-widget').attr('data-cycle-pass-id') + '/progress/' + $('#assessor-widget').attr('data-stage-progress-id') + '/assessors'
          : '/api/v1/publishedSurveys/' + $('#assessor-widget').attr('data-assessment-assignment-id') + '/assessors'
      },
      saveButton: $('#assessor-widget #save-button'),
      addButton: $('#assessor-widget #add-button'),
      removeButton: $('#assessor-widget #remove-button')
    },

    init: function () {
      AssessorWidgetConfig = this.settings
      this.loadAssessors()
      this.bindUIActions()
    },

    bindUIActions: function () {
      AssessorWidgetConfig.saveButton.on('click', function () {
        AssessorWidget.save()
      })
      AssessorWidgetConfig.addButton.on('click', function () {
        AssessorWidget.showRemoveButton()
        AssessorWidget.addAssessor()
      })
      AssessorWidgetConfig.removeButton.on('click', function () {
        AssessorWidget.removeAssessor()
      })
    },

    loadAssessors: function () {
      $.get(AssessorWidgetConfig.api.assessors, function (response) {
        const assessors = response.data

        if ((typeof assessors !== 'undefined') && assessors.length > 0) {
          for (let i = 0; i < assessors.length; i++) {
            AssessorWidget.newAssessor(assessors[i].name, assessors[i].job_title)
          }

          if ((typeof assessors !== 'undefined') && assessors.length > 1) {
            AssessorWidget.showRemoveButton()
          }
        } else {
          AssessorWidget.addAssessor()
        }
      })
    },

    addAssessor: function () {
      this.newAssessor('', '')
    },

    newAssessor: function (name, position) {
      AssessorWidgetConfig.assessors.append(
        '<input type="text" placeholder="Assessor Name/ID #" name="assessorNames[]" style="margin-bottom:5px"  value="' + name + '"/>' +
                '<input type="text" placeholder="Assessor Job Title" name="assessorPositions[]" style="margin-bottom:25px" value="' + position + '"/>'
      )
    },

    removeAssessor: function () {
      const assessorNames = AssessorWidgetConfig.assessors.find('input[name="assessorNames[]"]')
      const assessorPositions = AssessorWidgetConfig.assessors.find('input[name="assessorPositions[]"]')

      if (assessorNames.length > 1) {
        assessorNames.last().remove()
        assessorPositions.last().remove()
      }

      if (assessorNames.length === 2) {
        this.hideRemoveButton()
      }
    },

    hideRemoveButton: function () {
      AssessorWidgetConfig.removeButton.hide()
    },

    showRemoveButton: function () {
      AssessorWidgetConfig.removeButton.show()
    },

    save: function () {
      const names = AssessorWidgetConfig.assessors.find('input[name="assessorNames[]"]').map(function () {
        return $(this).val()
      })

      const positions = AssessorWidgetConfig.assessors.find('input[name="assessorPositions[]"]').map(function () {
        return $(this).val()
      })

      const assessors = []

      for (let i = 0; i < names.length; i++) {
        assessors[i] = {
          name: names[i],
          jobTitle: positions[i]
        }
      }

      const request = $.ajax({
        method: 'PUT',
        url: AssessorWidgetConfig.api.assessors,
        data: { assessors }
      })

      request.done(function () {
        alert('Assessor information has been saved!')
      })

      request.fail(function () {
        alert('Something went wrong, couldn\'t save!')
      })
    }
  }

  if ($('#assessor-widget').length) {
    AssessorWidget.init()
  }

  const $form = $('div.assessment-page-wrapper form')
  const assessmentAssignmentId = $form.find('input[name=assessmentAssignmentId]').val()
  const lastTimeRecorded = window.localStorage.getItem('time_' + assessmentAssignmentId)

  if (lastTimeRecorded !== null) {
    $form.find('input[name=time]').val(lastTimeRecorded)
  }

  var assessmentTimeRemaining = setInterval(function () {
    const $form = $('div.assessment-page-wrapper form')
    const $timer = $('.assessment-timer-wrapper')
    const assessmentAssignmentId = $form.find('input[name=assessmentAssignmentId]').val()
    const time = $form.find('input[name=time]').val()

    if (time > 0) {
      const newTime = time - 1
      $form.find('input[name=time]').val(newTime)
      window.localStorage.setItem('time_' + assessmentAssignmentId, newTime.toString())

      const hours = pad(Math.floor((newTime % (60 * 60 * 24)) / (60 * 60)), 2)
      const minutes = pad(Math.floor((newTime % (60 * 60)) / 60), 2)
      const seconds = pad(Math.floor((newTime % 60)), 2)
      let timeText = '<i class="fa fa-clock-o" aria-hidden="true"></i> '

      if (hours > 0) {
        timeText += hours + ':'
      }

      if (minutes > 0) {
        timeText += minutes + ':'
      } else {
        timeText += '00:'
      }

      if (seconds > 0) {
        timeText += seconds
      } else {
        timeText += '00'
      }

      if (newTime < 120) {
        $timer.css('color', '#ff1744')
      }

      $timer.html(timeText)
    }

    if (time <= 0) {
      clearInterval(assessmentTimeRemaining)
      $form.submit()
    }
  }, 1000)
})

function pad (str, max) {
  str = str.toString()
  return str.length < max ? pad('0' + str, max) : str
}

$(document).on('change', 'div.assessment-page-wrapper form div.assessment-comment-wrapper textarea', function (ev) {
  const $comment = $(ev.target)
  const $form = $comment.parents('div.assessment-page-wrapper form')
  const $question = $comment.parents('div.assessment-page-wrapper form div.assessment-question-wrapper')
  const key = $form.find('input[name=key]').val()
  const time = $form.find('input[name=time]').val()
  const questionId = $question.attr('data-question-id')
  const answerId = $question.find('div.assessment-answer-wrapper input[type=radio]:checked, div.assessment-answer-wrapper select').val()
  const answerText = $question.find('div.assessment-answer-wrapper input[type=text], div.assessment-answer-wrapper textarea').val()
  const comment = $comment.val()
  const assessmentAssignmentId = $form.find('input[name=assessmentAssignmentId]').val()

  updateRespondentAnswer(assessmentAssignmentId, key, questionId, answerId, answerText, comment, time)
})

$(document).on('change', 'div.assessment-page-wrapper form div.assessment-answer-wrapper input[type=radio], div.assessment-page-wrapper form div.assessment-answer-wrapper select', function (ev) {
  const $answerSelector = $(ev.target)
  const $form = $answerSelector.parents('div.assessment-page-wrapper form')
  const $question = $answerSelector.parents('div.assessment-page-wrapper form div.assessment-question-wrapper')
  const key = $form.find('input[name=key]').val()
  const time = $form.find('input[name=time]').val()
  const questionId = $question.attr('data-question-id')
  const answerId = $answerSelector.val()
  let answerText
  const comment = $question.find('div.assessment-comment-wrapper textarea').val()
  const assessmentAssignmentId = $form.find('input[name=assessmentAssignmentId]').val()

  if (answerId === 0) {
    $question.addClass('unanswered')
  } else {
    $question.removeClass('unanswered')

    if ($('.unanswered').length === 0) {
      $('#next-unanswered-question-button').hide()
    }
  }

  updateRespondentAnswer(assessmentAssignmentId, key, questionId, answerId, answerText, comment, time)
})

$(document).on('change', 'div.assessment-page-wrapper form div.assessment-answer-wrapper input[type=text], div.assessment-page-wrapper form div.assessment-answer-wrapper textarea', function (ev) {
  const $answerTextSelector = $(ev.target)
  const $form = $answerTextSelector.parents('div.assessment-page-wrapper form')
  const $question = $answerTextSelector.parents('div.assessment-page-wrapper form div.assessment-question-wrapper')
  const key = $form.find('input[name=key]').val()
  const time = $form.find('input[name=time]').val()
  const questionId = $question.attr('data-question-id')
  let answerId
  const answerText = $answerTextSelector.val()
  const comment = $question.find('div.assessment-comment-wrapper textarea').val()
  const assessmentAssignmentId = $form.find('input[name=assessmentAssignmentId]').val()

  if (answerText === '') {
    $question.addClass('unanswered')
  } else {
    $question.removeClass('unanswered')

    if ($('.unanswered').length === 0) {
      $('#next-unanswered-question-button').hide()
    }
  }

  updateRespondentAnswer(assessmentAssignmentId, key, questionId, answerId, answerText, comment, time)
})

$(document).on('click', 'div.assessment-page-wrapper form input.assessment-submit-button', function () {
  const $unanswered = $('div.assessment-page-wrapper form div.assessment-question-wrapper.unanswered')

  if ($unanswered.length > 0) {
    const $modal = $('#unanswered-question-modal')

    if ($modal.length === 0) {
      $('.content').append(
        '<div id="unanswered-question-modal" class="reveal-modal" data-reveal aria-labelledby="Unanswered question modal" aria-hidden="true" role="dialog">' +
                '<h3>Some questions are not answered. Do you still want to submit now?</h3>' +
                '<div class="row">' +
                    '<div class="small-12 columns">' +
                        '<input type="button" value ="Go back to page" class="button small radius unanswered-question-modal-go-back secondary">' +
                        '<input type="button" value ="Submit with unanswered questions" class="button small radius unanswered-question-modal-submit warning">' +
                    '</div>' +
                '</div>' +
            '</div>')
    }

    $('#unanswered-question-modal').foundation('reveal', 'open')
  } else {
    $('div.assessment-page-wrapper form').submit()
  }
})

$(document).on('click', '#next-unanswered-question-button', function () {
  goToNextUnansweredQuestion()
})

$(document).on('click', '.unanswered-question-modal-go-back', function () {
  $('#unanswered-question-modal').foundation('reveal', 'close')
  $('#next-unanswered-question-button').show()
  goToNextUnansweredQuestion()
})

$(document).on('click', '.unanswered-question-modal-submit', function () {
  $('div.assessment-page-wrapper form').submit()
})

function goToNextUnansweredQuestion () {
  $('html, body').animate({
    scrollTop: ($('.unanswered').first().offset().top - 75)
  }, 500)

  $('.unanswered').first().animate({ backgroundColor: '#fffde7' }, 1000)
  $('.unanswered').first().animate({ backgroundColor: 'transparent' }, 1000)
}

function updateRespondentAnswer (assessmentAssignmentId, key, questionId, answerId, answerText, comment, time) {
  $.post($('.assessment-page-wrapper').data('href'), { key, time, questionId, answerId, answerText, comment })
}
