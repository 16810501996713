import $ from 'jquery'
import 'datatables.net'
import _ from 'lodash'

export default class Filter {
  constructor () {
    this.activeFilters = []
    this.lastHash = window.location.hash
  }

  initFilters () {
    this._initView()
    this.activeFilters = this._parseParametersFromUrl()
    this._syncFilters()
  }

  removeFilterEvent (target) {
    this._markFilterInactive(target.dataset.filter, $(target).siblings('.active-value').text())
    this._syncFilters()
    this._redrawTable()
  }

  _initView () {
    const f = this
    const $filterBoxInput = $('.filter-box input')

    $('.filter-box select').change(function (event) {
      f._addNewFilterEvent(event)
    })

    $filterBoxInput.focusout(function (event) {
      f._addNewFilterEvent(event)
    })

    $filterBoxInput.keydown(function (event) {
      f._handleFilterKey(event)
    })

    $('#toggle-filters').click(function (event) {
      f._toggleFilters(event)
    })
  }

  _isFilterSingleValue (filter) {
    switch (filter) {
      case 'radius':
      case 'relocate':
        return true
      default:
        return false
    }
  }

  _handleFilterKey (event) {
    if (event.which === 13) {
      this._addNewFilterEvent(event)
    }
  }

  _convertStringToArray (str) {
    if (!str) return []
    const pairs = str.split('&')
    const queryArray = []

    pairs.forEach(function (pairString) {
      const pair = pairString.split('=')
      queryArray.push({ name: pair[0], value: pair[1] })
    })

    return queryArray
  }

  _parseParametersFromUrl () {
    return this._convertStringToArray(window.location.hash.slice(1))
  }

  _toggleFilters (target) {
    const $allFilters = $('#all-filters')

    if ($allFilters.css('display') === 'none') {
      $(target).html('<i class="fa fa-times" aria-hidden="true"></i> Close')
    } else {
      $(target).html('<i class="fa fa-plus" aria-hidden="true"></i> Add filter')
    }

    $allFilters.fadeToggle(200)
  }

  _markFilterActive (name, value) {
    const filterIsSingleValue = this._isFilterSingleValue(name)
    const filterAlreadyExists = this._isFilterActive(name, null)
    const filterAlreadyExistsWithValue = this._isFilterActive(name, value)
    const shouldReplaceFilter = (filterIsSingleValue && filterAlreadyExists)
    const shouldAddFilter = (
      (!filterIsSingleValue && !filterAlreadyExistsWithValue) ||
      (filterIsSingleValue && !filterAlreadyExists)
    )

    if (shouldReplaceFilter) {
      this._replaceFilterValue(name, value)
    } else if (shouldAddFilter) {
      this.activeFilters.push({ name, value })
    }
  }

  _addNewFilterEvent (event) {
    const target = event.target
    const value = $(target).val()
    if (!value) return
    const name = $(target).siblings('.filter-name').text()
    this._markFilterActive(name, value)
    this._syncFilters()
    this._redrawTable()
  }

  _resetAllInputs () {
    $('.filter-value').val('')
  }

  _removeAllFromView () {
    $('li.active-filter').remove()
  }

  _removeAllFromUrl () {
    window.location.hash = ''
  }

  _clearActiveFilters () {
    this._removeAllFromView()
    this._removeAllFromUrl()
    this._resetAllInputs()
  }

  _getTitleForFilter (name) {
    if (!name) return ''
    return $('.filter-name:contains("' + name + '")').siblings('.filter-title').text()
  }

  _addActiveFilterToView (name, title, value) {
    if (!name || !title || !value) {
      return
    }

    const innerHtml = '<span class="active-title">' + title + '</span><span>|</span><span class="active-value">' + value + '</span><span class="active-remove-filter" data-filter="' + name + '"><i class="fa fa-times" aria-hidden="true"></i></span>'
    $('#active-filters ul').append('<li class="active-filter" data-filter="' + name + '">' + innerHtml + '</li>')
  }

  _isFilterActive (name, value) {
    return _.size(_.filter(this.activeFilters, filter => {
      if (filter.name === name) {
        return value != null ? filter.value === value : true
      }

      return false
    })) > 0
  }

  _indexOfActiveFilter (name, value) {
    let index = -1

    this.activeFilters.forEach(function (filter, i) {
      if (filter.name === name) {
        if (value == null) {
          index = i
        } else if (filter.value === value) {
          index = i
        }
      }
    })

    return index
  }

  _markFilterInactive (name, value) {
    if (this._isFilterActive(name, value)) {
      this.activeFilters.splice(this._indexOfActiveFilter(name, value), 1)
    }
  }

  _activateFilter (name, value) {
    const title = this._getTitleForFilter(name)
    this._addActiveFilterToView(name, title, value)
    this._addFilterToUrl(name, value)
  }

  _replaceFilterValue (name, value) {
    this.activeFilters.forEach(function (filter, i) {
      if (filter.name === name) {
        this.activeFilters[i] = { name, value }
      }
    })
  }

  _syncFilters () {
    const f = this
    this._clearActiveFilters()
    this.activeFilters.forEach(function (filter) {
      f._activateFilter(filter.name, filter.value)
    })
  }

  _addFilterToUrl (name, value) {
    const parameter = name + '=' + value

    if (!window.location.hash) {
      window.location.hash += parameter
    } else {
      window.location.hash += ('&' + parameter)
    }
  }

  _redrawTable () {
    if (window.location.hash !== this.lastHash) {
      if (typeof $ === 'function') {
        const table = $('#applicant-table')

        if (table) {
          table.DataTable().draw()
          this.lastHash = window.location.hash
        }
      }
    }
  }
}
