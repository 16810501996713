import $ from 'jquery'
import dayjs from 'dayjs'

export default class Assessment {
  static load () {
    $('#pap_survey_container').sortable()

    $(document).on('click', '.pap-survey-delete', function () {
      $(this).closest('.pap-surveys').remove()
    })

    $(document).on('organization:change', function (event, clientId) {
      $.get('api/v1/clients/' + clientId + '/publishedSurveys?limit=0', function (data) {
        const assessments = data.items
        let html = ''

        if (assessments.length > 0) {
          html += '<option value="blank">Select assessment</option>'

          $.each(assessments, function (key, assessment) {
            html += '<option value="' + assessment.id + '">' + assessment.name + ' | ' + dayjs(assessment.created).format('MM-DD-YYYY hh:mm A') + '</option>'
          })

          $('.survey').html(html)
        } else {
          $('.survey').html('<option value="blank">No assessment found for this client</option>')
        }
      })
    })

    $('#add_pap_survey').click(function () {
      $('#add_pap_survey').attr('disabled', true)
      const assessment = '<li class="pap-surveys">' +
        '<select name="pap_survey[]" class="survey"></select>' +
        '<label for="pap_time_limit[]">Time Limit</label>' +
        '<input type="text" size="3" name="pap_time_limit[]" maxlength="3"/>' +
        '<span>Minutes</span>' +
        '<a class="pap-survey-delete"><i class="fa fa-2x fa-trash"></i></a>' +
        '</li>'

      $('#pap_survey_container').append(assessment)

      $.get('api/v1/clients/' + $('#cycle_ap_clientId').val() + '/publishedSurveys?limit=0', function (data) {
        const assessments = data.items
        let html = ''

        if (assessments.length > 0) {
          html += '<option value="blank">Select assessment</option>'

          $.each(assessments, function (key, assessment) {
            html += '<option value="' + assessment.id + '">' + assessment.name + ' | ' + dayjs(assessment.created).format('MM-DD-YYYY hh:mm A') + '</option>'
          })

          $('.survey:last').html(html)
          $('#add_pap_survey').attr('disabled', false)
        } else {
          $('.survey:last').html('<option value="blank">No assessment found for this client</option>')
          $('#add_pap_survey').attr('disabled', false)
        }
      })
    })
  }
}
