import $ from 'jquery'
import ActiveEndDate from './ActiveEndDate'

class CycleSkillBuilderCreate {
  load () {
    ActiveEndDate.load()
  }
}

const cycleCreate = new CycleSkillBuilderCreate()

$(function () {
  if ($('#cycle-skillbuilder-create, #cycle-skillbuilder-edit').length > 0) {
    cycleCreate.load()
  }
})
