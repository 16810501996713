import { Chart } from 'chart.js/auto'

export default function BulletChart (ctx, data, responsive = true) {
  return new Chart(ctx, {
    type: 'bar',
    data: data,
    options: {
      responsive: responsive,
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 0
        }
      },
      scales: {
        y: {
          stacked: true,
          display: false,
          ticks: {
            display: false
          },
          grid: {
            display: false
          }
        },
        x: {
          max: 100,
          display: false,
          stacked: true,
          ticks: {
            display: false
          },
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: { display: false },
        tooltip: false
      },
      layout: {
        padding: 0
      },
      interaction: false
    }
  })
}

export function createChart (ctx, data) {
  return new BulletChart(ctx, data)
}

export function getChartData (input, label = '', score = null) {
  input.sort((a, b) => a.start - b.start)
  const formattedData = []
  const chartColors = { r: 'rgba(255 0 0 / 50%)', y: 'rgba(255 255 0 / 50%)', g: 'rgba(0 255 0 / 50%)' }
  for (const data of input) {
    formattedData.push({
      backgroundColor: chartColors[data.color],
      data: [0, data.end - data.start, 0],
      barPercentage: 3,
      categoryPercentage: 1,
      start: data.start
    })
  }

  if (score !== null) {
    formattedData.push({
      label: 'Score',
      backgroundColor: 'rgba(0 0 0)',
      pointRadius: 0,
      pointHoverRadius: 0,
      hoverBorderWidth: 0,
      hitRadius: 0,
      pointStyle: 'line',
      borderWidth: 1.75,
      borderColor: 'rgba(0 0 0)',
      data: [
        {
          x: score,
          y: 1
        }, {
          x: score,
          y: 3
        }
      ],
      type: 'scatter',
      showLine: true
    })
  }

  return { datasets: formattedData, labels: [1, label, 3] }
}

export function getZoneString (zones) {
  zones.sort((a, b) => a.start - b.start)

  return zones.map(item => `${item.color},${item.end}`).join(';')
}

export function parseZoneString (zoneString) {
  const zones = zoneString.split(';')
  const zonesArray = []
  let lastVal = 0
  for (const zone of zones) {
    const z = zone.split(',')
    zonesArray.push({ color: z[0], start: parseInt(lastVal), end: parseInt(z[1]) })
    lastVal = z[1]
  }
  return zonesArray
}
