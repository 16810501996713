import $ from 'jquery'
import BigFiveChart from './charts/BigFiveChart'

function createPersonalityChart () {
  const ctx = document.getElementById('bigFiveDimensions')
  const data = JSON.parse($('#bigFiveDimensions').attr('data-values'))
  return new BigFiveChart(ctx, data)
}

$(function () {
  document.getElementById('bigFiveDimensions') && createPersonalityChart()
})
