import axios from 'axios'

export function getOrganizations (params, cancelToken = null) {
  const config = {
    method: 'get',
    url: '/api/v1/clients',
    params: params
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios organizations request via token.', config)
      } else {
        console.error('Organizations request error.', err)
        throw err
      }
    })
}

export function getOrganization (id, cancelToken = null) {
  const config = {
    method: 'GET',
    url: '/api/v1/clients/' + id
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios organization data request via token.', config)
      } else {
        console.error('Organization data request error.', err)
        throw err
      }
    })
}

export function getOrganizationLocations (id, limit = null, cancelToken = null) {
  const config = {
    method: 'get',
    url: `api/v1/clients/${id}/locations` + (limit !== null ? `?limit=${limit}` : '')
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios organization locations request via token.', config)
      } else {
        console.error(err)
        throw err
      }
    })
}
