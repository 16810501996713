import axios from 'axios'

export function postComments (json) {
  return axios({
    method: 'POST',
    url: '/api/v1/comments',
    data: json
  })
    .catch(function (error) {
      console.error(error)
      throw error
    })
}

export function updateComment (id, json) {
  return axios({
    method: 'POST',
    url: `/api/v1/comments/${id}`,
    data: json
  })
    .then(function (response) {
      return response.headers['x-resource-id']
    })
    .catch(function (error) {
      console.error(error)
      throw error
    })
}

export function deleteComment (id) {
  return axios({
    method: 'DELETE',
    url: `/api/v1/comments/${id}`
  })
    .then(() => true)
    .catch(function (err) {
      console.error(err)
      throw err
    })
}

export function removeTag (commentId, label) {
  return axios({
    method: 'POST',
    url: `/api/v1/comments/${commentId}/remove/tag`,
    data: { label }
  })
    .then(() => true)
    .catch(function (err) {
      console.error(err)
      throw err
    })
}

export function addTag (commentId, label) {
  return axios({
    method: 'POST',
    url: `/api/v1/comments/${commentId}/add/tag`,
    data: { label }
  })
    .then(() => true)
    .catch(function (err) {
      console.error(err)
      throw err
    })
}

export function listApplicantTags (applicantId) {
  return axios({
    method: 'GET',
    url: `/api/v1/comments/applicant/${applicantId}/tags`
  })
    .then(response => response.data)
    .catch(function (err) {
      console.error(err)
      throw err
    })
}
