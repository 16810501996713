import $ from 'jquery'
import tinymce from 'tinymce/tinymce'

$(function () {
  tinymce.init({
    branding: false,
    convert_urls: false,
    element_path: false,
    entity_encoding: 'raw',
    height: 775,
    selector: '.tinymce-full-featured',
    theme: 'silver',
    plugins: 'preview searchreplace autolink directionality visualblocks visualchars image link media codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern help',
    toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat'
  })

  tinymce.init({
    branding: false,
    convert_urls: false,
    element_path: false,
    entity_encoding: 'raw',
    height: 400,
    selector: '.tinymce-email',
    theme: 'silver',
    plugins: 'searchreplace autolink directionality visualblocks visualchars image link media codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern',
    toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt'
  })

  tinymce.init({
    branding: false,
    convert_urls: false,
    element_path: false,
    entity_encoding: 'raw',
    height: 300,
    selector: '.tinymce-simple',
    theme: 'silver',
    plugins: 'searchreplace autolink directionality visualblocks visualchars image link media charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern',
    toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
    setup: function (ed) {
      ed.on('init', function () {
        ed.execCommand('fontSize', false, '8pt')
      })
    }
  })
})
