/* eslint no-var: 0 */
import $ from 'jquery'
import ApplicantCard from './modules/ApplicantCard'

let appCard
$(document).on('ready', function () {
  if ($('#applicant-card.simple').length > 0) {
    appCard = new ApplicantCard()
    appCard.init(true)
  }
})

export function showRezviewModal (applicant) {
  var modal = findModal()

  $.post('/applicants/' + applicant + '/rezview', {}, function (response) {
    modal.html('')
    modal.append(response)
    modal.foundation('reveal', 'open')
  }, 'text')
}

export function showRedactedRezviewModal (applicant) {
  var modal = findModal()

  $.post('/applicants/' + applicant + '/rezview/redacted', {}, function (response) {
    modal.html('')
    modal.append(response)
    modal.foundation('reveal', 'open')
  }, 'text')
}

export function showFlagModal (applicant) {
  var modal = findModal()

  $.post('/applicants/' + applicant + '/all_flags', {}, function (response) {
    modal.html('')
    modal.append(response)
    modal.foundation('reveal', 'open')
  }, 'text')
}

export function findModal () {
  if (!$('.reveal-modal').length) {
    $('body').prepend('<div class="reveal-modal xlarge" data-reveal role="dialog"></div>')
  }

  return $('.reveal-modal').first()
}
