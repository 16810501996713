import { Chart } from 'chart.js/auto';

export default function ProgressChart (ctx, chart) {
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: chart.labels,
      datasets: [{
        label: '% completed',
        data: chart.data,
        backgroundColor: chart.color,
        borderWidth: 1,
        maxBarThickness: 35
      }]
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        bar: {
          borderWidth: 2
        }
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false
          }
        },
        y: {
          max: 100,
          ticks: {
            beginAtZero: true,
            stepSize: 20
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  })
}
