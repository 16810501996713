import $ from 'jquery'
import CycleUser from './CycleUser'
import Module from './Module'
import Integration from './Integration'
import ActiveEndDate from './ActiveEndDate'

class CycleHiringCreate {
  load () {
    const $cycleWrapper = $('.cycle-setup')

    ActiveEndDate.load()
    CycleUser.load()
    Module.load('#cycle_hiring_modules_')
    Integration.load($cycleWrapper)
  }
}

const cycleCreate = new CycleHiringCreate()

$(function () {
  if ($('#cycle-hiring-create, #cycle-hiring-edit').length > 0) {
    cycleCreate.load()
  }
})
