import $ from 'jquery'
import tinymce from 'tinymce'
import { getTemplate } from '../api/email_template_repository'

$(function () {
  let tConfig
  const TemplateWidget = {
    settings: {
      dropdown: $('#template-dropdown'),
      subject: $('#template-subject')
    },

    init: function () {
      tConfig = this.settings

      this.bindUIActions()

      tinymce.init({
        branding: false,
        convert_urls: false,
        element_path: false,
        entity_encoding: 'raw',
        height: 400,
        selector: '#template-body',
        theme: 'silver',
        plugins: 'searchreplace autolink directionality visualblocks visualchars image link media codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern',
        toolbar: 'formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
        fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt'
      }).then(function () {
        tConfig.dropdown.trigger('change')
      })
    },

    bindUIActions: function () {
      tConfig.dropdown.on('change', function () {
        if ($(this).val() === '0') {
          tConfig.subject.val('')
          TemplateWidget.updateBody('')
        } else {
          getTemplate(tConfig.dropdown.val()).then(template => {
            tConfig.subject.val(template.subject)
            TemplateWidget.updateBody(template.template)
          })
        }
      })
    },

    updateBody: function (body) {
      tinymce.activeEditor.setContent(body)
    }
  }

  if ($('#template-contact').length) {
    TemplateWidget.init()
  }
})
