import './../css/bundle.css'

import $ from 'jquery'
import 'jquery-migrate/dist/jquery-migrate.min.js'
import './jquery/plugins/DataView.js'

import './vendor/modernizr.js'
import 'jquery-ui-dist/jquery-ui.min.js'
import 'multiselect/js/jquery.multi-select.js'
import 'fastclick/lib/fastclick.js'
import 'select2/dist/js/select2.min.js'
import 'datatables.net/js/jquery.dataTables.min.js'
import 'datatables.net-zf/js/dataTables.foundation.min.js'
import 'datatables.net-scroller/js/dataTables.scroller.min.js'
import 'datatables.net-select/js/dataTables.select.min.js'
import './vendor/foundation.min.js'

import tinymce from 'tinymce/tinymce'

import 'tinymce/themes/silver/theme'

import 'tinymce/plugins/preview'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/table'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/help'

import './question.js'
import './widgets/email.js'
import './widgets/requestCycle.js'
import './assessmentplatform.js'

import './modules/CycleCreate/CycleHiringCreate'
import './modules/CycleCreate/HiringProgramCycleCreate'
import './modules/CycleCreate/CycleSkillBuilderCreate'
import './modules/CycleCreate/CycleAPCreate'
import './modules/ProcessDetailsWidget'
import './modules/DetailView'
import './survey.js'
import './app.js'
import './tinymce.js'
import { formatPercentileScore, convertHashToQuery } from './cycle.js'
import { togglePreview, loadTemplateContent } from './email.js'
import './formEvents'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

window.$ = window.jQuery = $

window.tinymce = tinymce
window.formatPercentileScore = formatPercentileScore
window.convertHashToQuery = convertHashToQuery
window.togglePreview = togglePreview
window.loadTemplateContent = loadTemplateContent
window.iso8601 = function (date) {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
}
