import $ from 'jquery'

class ProcessDetailsWidget {
  constructor () {
    this.statuses = {
      LOAD: 0,
      SUCCESS: 1,
      FAIL: 2
    }
  }

  init () {
    this.bindUIActions()
  }

  bindUIActions () {
    const self = this

    $(document).on('change', 'input[name=template-visibility]', function () {
      const templateVisibilityId = $(this).attr('data-templatevisibilityid')
      const value = !$(this).prop('checked')

      self.statusUpdate(self.statuses.LOAD)

      $.ajax({
        type: 'POST',
        url: '/api/v1/clients/templateVisibility/' + templateVisibilityId + '/toggle',
        data: { hidden: value },
        success: function () {
          self.statusUpdate(self.statuses.SUCCESS, 'Successfully ' + (value ? 'hid' : 'revealed') + ' template')
        }
      })
    })
  }

  statusUpdate (st = '', msg = '') {
    const self = this
    const element = $('span#update-status > span')
    let icon = ''
    $(element).finish().hide()
    switch (st) {
      case self.statuses.LOAD:
        $(element).css('color', '#5D676F')
        icon = '<i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw margin-bottom"></i>'
        break

      case self.statuses.SUCCESS:
        $(element).css('color', 'green')
        icon = '<i class="fa fa-check fa-2x fa-fw margin-bottom"></i>'
        break

      case self.statuses.FAIL:
        $(element).css('color', 'darkred')
        icon = '<i class="fa fa-times fa-2x fa-fw margin-bottom"></i>'
        break

      default:
        break
    }
    $(element).empty().append(icon + ' <strong>' + msg + '</strong>')
    $(element).show(100, function () {
      if (st === self.statuses.SUCCESS) {
        $(element).fadeOut(3000)
      }
    })
  }
}

$(document).ready(function () {
  if ($('div.organization-configuration-wrapper').length > 0) {
    const processDetailsWidget = new ProcessDetailsWidget()
    processDetailsWidget.init()
  }
})
