import { Chart } from 'chart.js'

export default function ApplicantStatusChart (ctx) {
  const labels = JSON.parse(ctx.dataset.labels)
  const data = JSON.parse(ctx.dataset.values)
  const chartColors = [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(255, 102, 255)',
    'rgb(204, 255, 153)',
    'rgb(102, 255, 255)',
    'rgb(255, 255, 102)'
  ]
  return new Chart(ctx, {
    type: 'pie',
    data: {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: chartColors
      }]
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10
          }
        },
        tooltips: {
          displayColors: false
        }
      }
    }
  })
}
