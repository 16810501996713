import $ from 'jquery'
import DateUtil from './../DateUtil'

export default class ActiveEndDate {
  static load () {
    const $active = $('.cycle-active-input')
    const $endDate = $('.cycle-end-date-input')

    $active.change(function () {
      if ($active.val() === '0') {
        $endDate.val(null)
      } else {
        $endDate.val(DateUtil.formatDate(new Date()))
      }
    })

    $endDate.change(function () {
      if ($endDate.val() === '' || (new Date()) < (new Date($endDate.val()))) {
        $active.val('0')
      } else {
        $active.val('1')
      }
    })
  }
}
