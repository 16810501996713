import $ from 'jquery'

export default class Integration {
  static load ($cycleWrapper) {
    const self = this
    const $icimsWrapper = $('#icims-job-id')
    const $adpWrapper = $('#adp-requisition-id')
    const $organizationInput = $('#cycle_hiring_clientId')

    $icimsWrapper.hide()
    $adpWrapper.hide()

    if (typeof $cycleWrapper.data('clientId') !== 'undefined') {
      self.checkClientIntegrations($cycleWrapper.data('clientId'))
    }

    if (typeof $organizationInput.val() !== 'undefined' && $organizationInput.val() !== '') {
      self.checkClientIntegrations($organizationInput.val())
    }

    $(document).on('organization:change', function (event, clientId) {
      const $icimsWrapper = $('#icims-job-id')
      const $adpWrapper = $('#adp-requisition-id')

      $icimsWrapper.hide()
      $adpWrapper.hide()

      if (clientId > 0) {
        self.checkClientIntegrations(clientId)
        self.populateADPRequisitions(clientId)
      }
    })
  }

  static checkClientIntegrations (clientId) {
    const $icimsWrapper = $('#icims-job-id')
    const $adpWrapper = $('#adp-requisition-id')

    $.get('api/v1/clients/' + clientId + '/integrations', function (response) {
      const integrations = response.data

      for (let i = 0; i < integrations.length; i++) {
        switch (integrations[i].integration.id) {
          case 2:
            $icimsWrapper.show()
            break
          case 3:
            $adpWrapper.show()
            break
        }
      }
    })
  }

  static populateADPRequisitions (clientId) {
    const $select = $('#adp-requisition-id select')

    $select.empty().append('<option value="">Select a Requisition</option>')

    $.get(
      '/api/v1/clients/' + clientId + '/adp-requisitions',
      function (data) {
        $.each(data.data, function (id, requisition) {
          $select.append(
            '<option value="' + requisition.id + '">' + requisition.job_title + ' | ID: ' + requisition.requisition_id + ' | Location: ' + requisition.locations.city + '</option>')
        })
      },
      'json'
    )
  }
}
